import React, { useState, useEffect } from 'react';

const UltimasVendas = () => {
  const [vendas, setVendas] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchVendas = async () => {
      try {
        const response = await fetch('https://decalcinha-nodejs.vercel.app/vendas');
        // const response = await fetch('http://127.0.0.1:3000/vendas');
        if (!response.ok) {
          throw new Error('Erro ao buscar as vendas');
        }
        const data = await response.json();

        // Ordena as vendas para que as últimas inserções apareçam primeiro
        const vendasOrdenadas = data.reverse();

        setVendas(vendasOrdenadas.slice(0, 5)); // Pega apenas as últimas 5 vendas
      } catch (error) {
        console.error('Erro ao buscar vendas:', error);
        setErrorMessage('Erro ao buscar vendas');
      }
    };

    fetchVendas();
  }, []);

  return (
    <div className="vendas-recentes mt-6 p-6 bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-lg">
      <h1 className="text-red-500 mb-4 text-xl font-bold text-center">Últimas 5 Vendas</h1>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <ul className="space-y-4">
        {vendas.map((venda, index) => (
          <li
            key={index}
            className="border border-gray-300 p-4 rounded-lg shadow-sm bg-white hover:shadow-md hover:-translate-y-1 transition-transform duration-200"
          >
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Produto:</strong> {venda.produtos}
            </p>
            <p className='mb-1'>
            <strong className="font-semibold text-gray-700">Quantidade:</strong> {venda.quantidade}
            </p>
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Cliente:</strong> {venda.cliente}
            </p>
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Data:</strong>
              {new Date(new Date(venda.data).setDate(new Date(venda.data).getDate() + 1)).toLocaleDateString()}
            </p>
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Forma de Pagamento:</strong> {venda.formaPagamento}
            </p>
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Brinde:</strong> {venda.brinde}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UltimasVendas;