import React, { useState, useEffect } from 'react';
import '../index.css';

const Informacoes = ({ isAuthenticated }) => {
  const [produtosDisponiveis, setProdutosDisponiveis] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      const fetchProdutos = async () => {
        try {
          const response = await fetch('https://decalcinha-nodejs.vercel.app/produtos'); // URL atualizada
          if (!response.ok) {
            throw new Error('Erro ao buscar a lista de produtos');
          }
          const data = await response.json();
          setProdutosDisponiveis(data.produtos);
        } catch (error) {
          console.error('Erro ao buscar produtos:', error);
          setErrorMessage('Erro ao buscar produtos');
        }
      };

      fetchProdutos();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredProdutos(produtosDisponiveis);
    } else {
      const filtered = produtosDisponiveis.filter((produto) =>
        produto.nome.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProdutos(filtered);
    }
  }, [searchTerm, produtosDisponiveis]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="info-page p-2 sm:p-4">
      <div className="info-container bg-white p-2 sm:p-4 rounded-lg shadow-2xl border border-gray-200 overflow-auto sticky top-4 max-w-full sm:max-w-md lg:max-w-lg xl:max-w-xl" style={{ maxHeight: '500px' }}>
        <h3 className="info-title text-lg sm:text-xl font-semibold mb-2 sm:mb-4 border-b pb-1 sm:pb-2 text-gray-800">Informações de Estoque</h3>
        {errorMessage && <p className="info-error text-red-500 mb-2">{errorMessage}</p>}

        <div className="relative mb-2 sm:mb-4">
          <input
            className="info-search border border-gray-300 rounded-full p-2 sm:p-3 pl-8 sm:pl-10 w-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
            type="text"
            placeholder="Pesquisar produto..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg
            className="absolute left-2 sm:left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width="16"
            height="16"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4a7 7 0 0111 7c0 3.9-3.1 7-7 7a7 7 0 01-7-7z" />
          </svg>
        </div>

        <div className="table-wrapper overflow-x-auto max-h-60 sm:max-h-80">
          {filteredProdutos.length > 0 ? (
            <table className="info-table w-full border-collapse border border-gray-300 text-xs sm:text-sm">
              <thead>
                <tr className="table-header bg-blue-100 text-gray-800">
                  <th className="border border-gray-300 p-1 sm:p-2 text-left">Produto</th>
                  <th className="border border-gray-300 p-1 sm:p-2 text-left">Estoque</th>
                  <th className="border border-gray-300 p-1 sm:p-2 text-left">Preço</th>
                </tr>
              </thead>
              <tbody>
                {filteredProdutos
                  .filter((produto) => produto.estoque > 0)
                  .map((produto, index) => (
                    <tr key={index} className="table-row odd:bg-white even:bg-gray-50 hover:bg-blue-50 transition-colors">
                      <td className="border border-gray-300 p-1 sm:p-2">{produto.nome}</td>
                      <td className="border border-gray-300 p-1 sm:p-2">{produto.estoque}</td>
                      <td className="border border-gray-300 p-1 sm:p-2">{produto.preco}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div className="no-results text-center text-gray-500">Nenhum produto encontrado</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Informacoes;