import React, { useState, useEffect, useRef } from 'react';

const Formulario = ({ isAuthenticated }) => {
  const [formData, setFormData] = useState({
    produtos: [],
    produtoAtual: '',
    quantidadeAtual: '1',
    data: '',
    cliente: '',
    formaPagamento: '',
    brinde: ''
  });
  const [produtosDisponiveis, setProdutosDisponiveis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Ref para o campo de produto
  const produtoInputRef = useRef(null);

  useEffect(() => {
    const fetchProdutos = async () => {
      try {
        const response = await fetch('https://decalcinha-nodejs.vercel.app/produtos'); // URL atualizada
        if (!response.ok) {
          throw new Error('Erro ao buscar a lista de produtos');
        }
        const data = await response.json();
        
        // Filtra apenas produtos com estoque maior que 0
        const produtosComEstoque = data.produtos.filter(produto => produto.estoque > 0);
        
        setProdutosDisponiveis(produtosComEstoque);
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
        setErrorMessage('Erro ao buscar produtos');
      }
    };
  
    fetchProdutos();
  }, []);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'quantidadeAtual' && value !== '' && !/^\d+$/.test(value)) {
      return; // Impede a entrada de valores não numéricos
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleAddProduto = () => {
    const produtoSelecionado = produtosDisponiveis.find(
      (produto) => produto.nome === formData.produtoAtual
    );
  
    if (!produtoSelecionado) {
      setErrorMessage('Produto não encontrado');
      return;
    }
  
    if (produtoSelecionado.estoque === 0) {
      setErrorMessage(`O produto "${produtoSelecionado.nome}" está com estoque vazio.`);
      return;
    }

    const quantidade = parseInt(formData.quantidadeAtual, 10);

    // Soma total já adicionada para o produto
    const quantidadeJaAdicionada = formData.produtos
      .filter((produto) => produto.nome === produtoSelecionado.nome)
      .reduce((acc, produto) => acc + produto.quantidade, 0);

    // Verifica se o limite do estoque será ultrapassado
    if (quantidade + quantidadeJaAdicionada > produtoSelecionado.estoque) {
      setErrorMessage(`A quantidade total para "${produtoSelecionado.nome}" não pode exceder o estoque disponível (${produtoSelecionado.estoque}).`);
      return;
    }

    if (formData.produtoAtual && formData.quantidadeAtual) {
      const preco = produtoSelecionado.preco;
  
      // Criar o novo produto a ser adicionado
      const novoProduto = {
        nome: formData.produtoAtual,
        quantidade,
        estoque: produtoSelecionado.estoque,
        preco,
      };
  
      // Adicionar o novo produto à lista
      const novosProdutos = [...formData.produtos, novoProduto];
  
      // Calcular o total geral com os produtos atualizados
      const totalGeral = novosProdutos.reduce((acc, produto) => {
        const preco = parseFloat(produto.preco.replace('R$', '').replace(',', '.').trim() || '0');
        return acc + produto.quantidade * preco;
      }, 0);
  
      // Atualizar o estado
      setFormData({
        ...formData,
        produtos: novosProdutos,
        produtoAtual: '',
        quantidadeAtual: '1',
      });
  
      console.log('Total Geral Atualizado: R$', totalGeral.toFixed(2));
  
      // Limpar mensagens de erro e focar no campo
      setErrorMessage('');
      if (produtoInputRef.current) {
        produtoInputRef.current.focus();
        produtoInputRef.current.select();
      }
    }
  };

  
  const handleRemoveProduto = (index) => {
    const updatedProdutos = formData.produtos.filter((_, i) => i !== index);
    setFormData({ ...formData, produtos: updatedProdutos });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await fetch('https://decalcinha-nodejs.vercel.app/submit', { // Endpoint específico para submissão
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error || 'Erro desconhecido ao enviar os dados');
      }

      const result = await response.json();
      setSuccessMessage(result.message);
      setFormData({
        produtos: [],
        produtoAtual: '',
        quantidadeAtual: '1',
        data: '',
        cliente: '',
        formaPagamento: '',
        brinde: ''
      });
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      setErrorMessage(error.message || 'Erro ao enviar dados.');
    } finally {
      setLoading(false);
    }
  };

  if (!isAuthenticated) {
    return <p>Por favor, faça login para acessar o formulário.</p>;
  }

  return (
    <form className="order-form-container bg-white p-4 sm:p-6 rounded-lg shadow-2xl border border-gray-200 max-w-full sm:max-w-lg lg:max-w-xl" onSubmit={handleSubmit}>
      {loading && <p className="order-form-loading text-blue-600 mb-2">Enviando dados...</p>}
      {errorMessage && <p className="order-form-error text-red-500 mb-2">{errorMessage}</p>}
      {successMessage && <p className="order-form-success text-green-500 mb-2">{successMessage}</p>}

      <div className="order-form-group mb-3 sm:mb-4">
        <label className="order-form-label block text-gray-700 font-medium mb-1 sm:mb-2">Produto:</label>
        <input
          ref={produtoInputRef}
          className="order-form-input border border-gray-300 rounded-full p-2 sm:p-3 w-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
          list="produtos"
          type="text"
          name="produtoAtual"
          value={formData.produtoAtual}
          onChange={handleChange}
          autoFocus // Adiciona o foco automático no campo de produto
        />

        <datalist id="produtos" className="order-form-datalist">
          {produtosDisponiveis.map((produto, index) => (
            <option key={index} value={produto.nome} className="order-form-option">
              {produto.nome} - Estoque: {produto.estoque}
            </option>
          ))}
        </datalist>
      </div>

      <div className="order-form-group mb-3 sm:mb-4">
  <label className="order-form-label block text-gray-700 font-medium mb-1 sm:mb-2">Quantidade:</label>
  <input
    className="order-form-input border border-gray-300 rounded-full p-2 sm:p-3 w-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
    type="number"
    name="quantidadeAtual"
    value={formData.quantidadeAtual}
    onChange={handleChange}
  />
  <button className="order-form-button mt-2 bg-blue-500 text-white rounded-full p-2 sm:p-3 shadow hover:bg-blue-600 transition-colors" type="button" onClick={handleAddProduto}>
    Adicionar Produto
  </button>
</div>

<div className="order-form-group mb-3 sm:mb-4">
  <h4 className="order-form-subtitle text-base sm:text-lg font-semibold mb-1 sm:mb-2 text-gray-800 border-b pb-1 sm:pb-2">Produtos Adicionados:</h4>
  <ul className="order-form-list space-y-2">
    {formData.produtos.map((produto, index) => (
      <li key={index} className="order-form-list-item bg-gray-100 p-2 sm:p-3 rounded-lg flex justify-between items-center shadow-sm">
        <span className="text-sm sm:text-base">
          {produto.nome} - Qtd: {produto.quantidade} - Estoque: {produto.estoque} - R$ {(
            produto.quantidade * parseFloat(produto.preco.replace('R$', '').replace(',', '.').trim() || '0')
          ).toFixed(2)}
        </span>
        <button
          className="order-form-remove-button bg-red-500 text-white p-2 rounded-full shadow hover:bg-red-600 transition-colors"
          type="button"
          onClick={() => handleRemoveProduto(index)}
        >
          Remover
        </button>
      </li>
    ))}
  </ul>

  {/* Total Geral Calculado */}
  <div className="order-form-total mt-4 text-lg font-bold">
    Total: R$ {formData.produtos.reduce((acc, produto) => {
      const preco = parseFloat(produto.preco.replace('R$', '').replace(',', '.').trim() || '0');
      return acc + produto.quantidade * preco;
    }, 0).toFixed(2)}
  </div>
</div>


      <div className="order-form-group mb-3 sm:mb-4">
        <label className="order-form-label block text-gray-700 font-medium mb-1 sm:mb-2">Data:</label>
        <input
          className="order-form-input border border-gray-300 rounded-full p-2 sm:p-3 w-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
          type="date"
          name="data"
          value={formData.data}
          onChange={handleChange}
          required
        />
      </div>

      <div className="order-form-group mb-3 sm:mb-4">
        <label className="order-form-label block text-gray-700 font-medium mb-1 sm:mb-2">Cliente:</label>
        <input
          className="order-form-input border border-gray-300 rounded-full p-2 sm:p-3 w-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
          type="text"
          name="cliente"
          value={formData.cliente}
          onChange={handleChange}
          required
        />
      </div>

      <div className="order-form-group mb-3 sm:mb-4">
        <label className="order-form-label block text-gray-700 font-medium mb-1 sm:mb-2">Forma de pagamento:</label>
        <select
          className="order-form-select border border-gray-300 rounded-full p-2 sm:p-3 w-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
          name="formaPagamento"
          value={formData.formaPagamento}
          onChange={handleChange}
          required
        >
          <option value="">Selecione</option>
          <option value="Pendente">Pendente</option>
          <option value="Provador">Provador</option>
          <option value="Cartão de Crédito">Cartão de Crédito</option>
          <option value="Boleto">Boleto</option>
          <option value="Pix">Pix</option>
          <option value="Dinheiro">Dinheiro</option>
          <option value="Transferência Bancária">Transferência Bancária</option>
        </select>
      </div>

      <div className="order-form-group mb-3 sm:mb-4">
  <label className="order-form-label block text-gray-700 font-medium mb-1 sm:mb-2">
    Brinde:
  </label>
  <input
    className="order-form-input border border-gray-300 rounded-full p-2 sm:p-3 w-full shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
    type="number"
    name="brinde"
    value={formData.brinde || ""}
    onChange={(e) => handleChange({ target: { name: e.target.name, value: Number(e.target.value) } })}
  />
</div>

      <button className="order-form-submit-button bg-blue-500 text-white p-2 sm:p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors w-full" type="submit" disabled={loading}>
        Enviar
      </button>
    </form>
  );
};

export default Formulario;